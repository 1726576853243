import moment from "moment";
import { toast } from "react-toastify";
import {
    ALERT_INFO,
    ALERT_WARNING,
    ALERT_ERROR,
    ALERT_SUCCESS,
} from "./constants/constants";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value !== "" && isValid;
        // isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isAlphabetsNumeric) {
        const pattern = /^[A-Za-z0-9\s_-]+$/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isAlphabets) {
        const pattern = /^[a-zA-Z ]*$/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isEmail) {
        const pattern =
            /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isGSTIN) {
        const pattern =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isIFSC) {
        const pattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isAge) {
        isValid = value >= 18 && isValid;
    }
    // if (rules.isNumber) {
    //     const pattern = /^[0-9\b]+$/;
    //     isValid = pattern.test(value) && isValid
    // }
    if (rules.isPan) {
        const pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isAadhaar) {
        //const pattern =  /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/; //with space validation
        const pattern = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isUrl) {
        const pattern =
            /https?:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isUpiId) {
        // const pattern = /^\w.+@\w+$/;
        const pattern = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isBank) {
        const pattern = /^[A-Z0-9\s_-]+$/;
        isValid = pattern.test(value) && isValid;
    }
    return isValid;
};

export const removeWhiteSpace = (val) => {
    return val.trim();
};

export const get_Checkbox_CheckedCount = (item) => {
    let length = Object.values(item).filter((x) => x.isChecked).length;
    return length;
};

export const randomStringGenrator = (length) => {
    let result = [];
    let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join("");
};

// Toast message alter

export const ToastAlert = (obj) => {
    switch (obj.type) {
        case ALERT_INFO:
            return toast.info(obj.msg);
        case ALERT_WARNING:
            return toast.warning(obj.msg);
        case ALERT_ERROR:
            return toast.error(obj.msg);
        case ALERT_SUCCESS:
            return toast.success(obj.msg);
        default:
            break;
    }
};

export const objDeepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const removeLastComma = (str) => {
    return str.replace(/,\s*$/, "");
};

export const getServerError = (error) => {
    let message = "";
    if (error.response) {
        //console.log("error.response", error.response);
        let result = error.response?.data;
        if (error.response.status === 500) {
            message = `${error.response.data}-${error.response.statusText}`;
        } else if (error.response.status === 403) {
            message = error.response.data;
        } else if (error.response.status === 404) {
            message = error.response.data;
        } else if (error.response.status === 409) {
            message = result?.errorMessage
                ? result.errorMessage
                : result?.message
                ? result.message
                : result;
        } else if (error.response.status === 422) {
            message = result.error[0]["msg"];
        }
    } else {
        message = "Something went wrong";
    }
    return message;
};

export const checkItemInArray = (array, val, key, key2, isArray = false) => {
    if (array.length > 0) {
        return array.findIndex((obj) => {
            if (isArray) {
                //console.log("match > ", `${obj[key][key2].toLowerCase()} === ${val.toLowerCase()}`, obj[key][key2].toLowerCase() === val.toLowerCase());
                return (
                    (obj[key][key2] ? obj[key][key2] : "").toLowerCase() ===
                    val.toLowerCase()
                );
            } else {
                //console.log("match > ", `${obj[key].toLowerCase()} === ${val.toLowerCase()}`, obj[key].toLowerCase() === val.toLowerCase());
                return (
                    (obj[key] ? obj[key] : "").toLowerCase() ===
                    val.toLowerCase()
                );
            }
        });
    } else {
        return -1;
    }
};

export const isPermissionAllowed = (array, val) => {
    return checkItemInArray(array, val, "modulePermission") !== -1
        ? true
        : false;
};

export const showHideMenu = (permissionArray, menuPermissions) => {
    return permissionArray.findIndex((p) => {
        //console.log("p.modulePermission", p.modulePermission)
        //console.log("menu.permissions", menu.permissions)
        return menuPermissions.includes(p.modulePermission);
    });
};

//===============
export const checkWindowViewPort = (mediaQueryString) => {
    let mediaQueryList = window.matchMedia(mediaQueryString);
    return mediaQueryList.matches;
};

//======================File Upload extension check=======================

export const checkFileExtValid = (allowedExtensions, file) => {
    let allowed = true;
    let ext = file.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(ext)) {
        allowed = false;
    }
    return allowed;
};

//Current date
export const getTodayDate = () => {
    let someDate = new Date();
    someDate.setDate(someDate.getDate());
    let todatDate = someDate.toISOString().substr(0, 10);
    return todatDate;
};

//Current date
export const getTodayDateTime = () => {
    //2021-07-17T14:13
    return moment().format("YYYY-MM-DDTHH:mm");
};

export const dateCustomFormat = (date, format, isCon = false) => {
    if (isCon === true) {
        return moment(date).utc().format(format); //convert with utc
    } else {
        return moment(date).format(format);
    }
};

export const getYears = () => {
    let years = [];
    for (let index = 5; index >= 0; index--) {
        let y =
            index === 0
                ? new Date().getFullYear()
                : new Date().getFullYear() - index;
        years.push(y);
    }
    return years;
};

export const firstLetterCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export function putVariableInString(str) {
    var args = [].slice.call(arguments, 1),
        i = 0;
    return str.replace(/%s/g, () => args[i++]);
}

export const isNumericCheck = (val, isDecimalCheck = false) => {
    if (isDecimalCheck === true) {
        const pattern = /^\d*\.?\d*$/;
        return pattern.test(val);
    } else {
        const pattern = /^\d+$/;
        return pattern.test(val);
    }
};
export const calculateAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const matchGstinNumberWithPanNumber = (gstno, pan) => {
    let match = false;
    var gstpan = gstno.substr(2, 10);
    if (gstpan === pan) {
        match = true;
    }
    return match;
};

export const matchGstinNumberWithStateCode = (gstno, stateCode) => {
    let match = false;
    var gstState = gstno.substr(0, 2);
    if (gstState === stateCode) {
        match = true;
    }
    return match;
};

export const getGSTAmount = (
    originalCost,
    gstPercent,
    reverseCharge = false
) => {
    let gstAmount = 0;
    if (reverseCharge) {
        gstAmount = (originalCost / (100 + gstPercent)) * gstPercent;
    } else {
        gstAmount = (originalCost * gstPercent) / 100;
    }
    return gstAmount;
};

export const filterDataBtTwoDates = (obj) => {
    let { dataArray, startDate, endDate, key } = obj;
    startDate = new Date(startDate).getTime();
    endDate = new Date(endDate).getTime();

    return dataArray.filter(function (d) {
        var time = new Date(d[key]).getTime();
        console.log("time", time);
        return time >= startDate && time <= endDate;
    });
};

// multi sort from array
export const multiSort = (array, sortObject = {}) => {
    const sortKeys = Object.keys(sortObject);

    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
        return array;
    }

    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
        sortObject[key] =
            sortObject[key] === "desc" || sortObject[key] === -1
                ? -1
                : sortObject[key] === "skip" || sortObject[key] === 0
                ? 0
                : 1;
    }

    const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;

        if (a === b) {
            // If the values are the same, do not switch positions.
            return a > b ? direction : -1 * direction;
        }

        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
    };

    return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;

        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
            const key = sortKeys[index];

            if (key) {
                const direction = sortObject[key];

                sorted = keySort(a[key], b[key], direction);
                index++;
            }
        }
        return sorted;
    });
};

//Number with commas as thousands separators
export const numberWithCommas = (num) => {
    if (num) {
        // using locale
        return new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR", // USD, EUR, CNY
        }).format(num);
        //return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        //return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return null;
    }
};

//Add .00 (toFixed) only if number has less than two decimal places
export const addZeroes = (num, fd) => {
    return num.toLocaleString("en", {
        useGrouping: false,
        minimumFractionDigits: fd,
    });
};

export const getInfo = (value, suffix) => {
    return value ? `${value}${suffix}` : "-";
};

export const isParentRow = (rowdata, viewContent) => {
    return rowdata.isParentRow && rowdata.isParentRow === true
        ? viewContent
        : null;
};

//-----Scroll Down Page-----------
export const scroll_page_section = (id) => {
    const row_selection = document.getElementById(`${id}`);
    row_selection.scrollIntoView({ behavior: "smooth", block: "center" });
};

//--------Routes mmanage----------
/**
 * Combine paths
 *
 * @param {string} parent
 * @param {string} child
 * @returns {string}
 */
export const combinePaths = (parent, child) =>
    `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

/**
 * Recursively build paths for each navigation item
 *
 * @param routes
 * @param {string} parentPath
 * @returns {*}
 */
export const buildPaths = (routes, parentPath = "") =>
    routes.map((route) => {
        let path = "";
        if (route.path !== "#/") {
            path = combinePaths(parentPath, route.path);
        }
        return {
            ...route,
            path,
            ...(route.routes && { routes: buildPaths(route.routes, "") }),
        };
    });

/**
 * Recursively provide parent reference for each navigation item
 *
 * @param routes
 * @param parentRoute
 * @returns {*}
 */
export const setupParents = (routes, parentRoute = null) =>
    routes.map((route) => {
        const withParent = {
            ...route,
            ...(parentRoute && { parent: parentRoute }),
        };

        return {
            ...withParent,
            ...(withParent.routes && {
                routes: setupParents(withParent.routes, withParent),
            }),
        };
    });

/**
 * Convert navigation tree into flat array
 *
 * @param routes
 * @returns {any[]}
 */
export const flattenRoutes = (routes) =>
    routes
        .map((route) => [
            route.routes ? flattenRoutes(route.routes) : [],
            route,
        ])
        .flat(Infinity);

/**
 * Combine all the above functions together
 *
 * @param routes
 * @returns {any[]}
 */
export const generateAppRoutes = (routes) => {
    return flattenRoutes(setupParents(buildPaths(routes))).filter(
        (d) => d.path !== ""
    );
};

/**
 * Provides path from root to the element
 *
 * @param route
 * @returns {any[]}
 */
export const pathTo = (route) => {
    if (!route.parent) {
        return [route];
    }

    return [...pathTo(route.parent), route];
};


export const roundOff = (value)=>{
    let afterRoundOff = Math.round(value)
    return afterRoundOff ;
}